
.container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 50% 50%;
  gap: 10px 10px;
  width: 100%;
  height: auto;
  position: relative;

  grid-template-areas:
    "image0 image1 image2"
    "image3 image4 image5";

  @media (min-width: 600px) and (max-width: 1200px) {
    grid-template-columns: auto;
    grid-template-rows: auto;
    grid-template-areas:
      "image0 image1"
      "image2 image3"
      "image4 image5";
  }

  @media screen and (max-width: 600px) {
    grid-template-columns: auto;
    grid-template-rows: auto;
    grid-template-areas:
      "image0"
      "image1"
      "image2"
      "image3"
      "image4"
      "image5";
  }
}

.cardContainer {
min-height: calc(45vh * 2);
margin-top: 20px;
display: flex;

@media screen and (min-width: 900px) and (max-width: 1280px){
  min-height: calc(33vh * 3);
}
@media screen and (max-width: 900px){
  min-height: calc(33vh * 2);
}
}

.containerMain {
    align-items: center;
    flex-direction: column;
    justify-content: flex-start
}
