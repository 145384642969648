.image0 {
    grid-area: image0;
  }
  
  .image1 {
    grid-area: image1;
  }
  
  .image2 {
    grid-area: image2;
  }
  
  .image3 {
    grid-area: image3;
  }
  
  .image4 {
    grid-area: image4;
  }
  
  .image5 {
    grid-area: image5;
  }

  .imageContainer {
    width: 100%;
    height: 100%;
    border-radius: 15px;
    background-color: #ADADAD;
  }

  .columnDiv {
    flex-direction: column;
  }
  .rowDiv {
    flex-direction: row; justify-content: flex-end; display: flex
  }
  .descriptionName {
    letter-spacing: 0.2px; margin-bottom: 0px; font-size: 20px; color: white
  }
  .descriptionRest {
    letter-spacing: 0.2px; margin-bottom: 0px; font-size: 14px; color: #ffffffAA; font-weight: bold  }

  @mixin text($grid-area) {
    grid-area: $grid-area;
    position: absolute;
    top: 20px;
    left: 15px;
    z-index: 1;
    width: 90%;
  }
  .text0{
    @include text(image0);
  }
  .text1{
    @include text(image1);
  }
  .text2{
    @include text(image2);
  }
  .text3{
    @include text(image3);
  }
  .text4{
    @include text(image4);
  }
  .text5{
    @include text(image5);
  }

  @mixin button($grid-area) {
    grid-area: $grid-area;
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.6);
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    padding: 10px;
    @media screen and (max-width: 900px) {
      display: none;
    }
  }

  
  .button0 {
    @include button(image0);
  }
  .button1 {
    @include button(image1);
  }
  .button2 {
    @include button(image2);
  }
  .button3 {
    @include button(image3);
  }
  .button4 {
    @include button(image4);
  }
  .button5 {
    @include button(image5);
  }
  
  .custom-img {
    width: 100% !important;
    position: relative !important;
    height: unset;
    border-radius: 15px;
    object-fit: cover
  }
  
  .unset-img {
    width: 100%;
  }
  
  .unset-img>div {
    position: unset;
  }